import { useSearchParams } from 'react-router-dom'
import { Stack, Typography, Unstable_Grid2 as Grid } from '@mui/material'
import { type MaterialTypes } from '@trinity/utils'
import { MaterialCard, Search } from '../../components'
import { useGlobalState } from '../../hooks'
import { BottomActionBar } from './helpers'

interface StyleflowVisualizeZippersProps {
  zippers: TrinityAPI.ZipperType[]
  materialList: MaterialTypes[]
}

export function StyleflowVisualizeZippers({ zippers, materialList }: StyleflowVisualizeZippersProps) {
  const { onMobile, onTablet } = useGlobalState()
  const isMobile = onMobile || onTablet
  const zipperIndex = materialList.findIndex(material => material === 'zipper')

  return (
    <Stack spacing={4} pb={14}>
      <Typography variant='h5' align={isMobile ? 'center' : 'left'}>
        Zippers
      </Typography>
      <Stack px={1}>
        <Search name='q' method='get' />
      </Stack>
      <ZipperList zippers={zippers} />
      <BottomActionBar lastMaterial={materialList[zipperIndex - 1]} nextMaterial={materialList[zipperIndex + 1]} />
    </Stack>
  )
}

function ZipperList({ zippers }: Pick<StyleflowVisualizeZippersProps, 'zippers'>) {
  const [params, setParams] = useSearchParams()

  return (
    <Grid container spacing={2} justifyContent={{ mobile: 'center', laptop: 'flex-start' }}>
      {zippers.map(zipper => (
        <Grid key={zipper.id}>
          <MaterialCard
            onClick={() =>
              setParams(params => {
                params.set('zipperId', String(zipper.id))
                return params
              })
            }
            image={zipper.image}
            headerText={zipper.value}
            subText={zipper.description}
            selected={String(zipper.id) === params.get('zipperId')}
          />
        </Grid>
      ))}
    </Grid>
  )
}
