import { Outlet, Link } from 'react-router-dom'
import { Box, Button, Divider, Stack, Typography } from '@mui/material'
import { ImageNotFound } from '../../assets'
import { ImageStepper } from '../../components'
import { useGlobalState, useWindowSize } from '../../hooks'

interface StyleflowVisualizeProps {
  visualizerFabric?: TrinityAPI.VisualizerFabricType
}

export function StyleflowVisualize({ visualizerFabric = undefined }: StyleflowVisualizeProps) {
  const { onMobile, onTablet } = useGlobalState()
  const { height } = useWindowSize()
  const isSuit = visualizerFabric?.garmentType?.formalName === 'Suit'
  const isMobile = onMobile || onTablet
  const suitSize = isMobile ? 300 : 600
  const clothingSize = isMobile ? 450 : 900
  const windowSize = height - 345

  return (
    <Stack direction={{ mobile: 'column', laptop: 'row' }} spacing={{ mobile: 8, laptop: 0 }} justifyContent='flex-end'>
      <Box
        bgcolor='neutral.extraLight'
        position={{ mobile: 'absolute' }}
        left={0}
        bottom={{ mobile: 'auto', laptop: 0 }}
        top={{ mobile: 80, laptop: 90 }}
        right={{ mobile: 0, laptop: '50%' }}
        pt={{ mobile: 8, laptop: 0 }}
      >
        <ImageStepper alignItems='center' justifyContent='center' height={1}>
          <img
            src={visualizerFabric?.renderedImage ?? ImageNotFound}
            alt={`${visualizerFabric?.fabric?.description} ${visualizerFabric?.garmentType.formalName}`}
            height={isSuit ? suitSize : clothingSize}
            width={isMobile ? 300 : 600}
          />
          <img
            src={visualizerFabric?.fabric?.swatch ?? ImageNotFound}
            alt={`${visualizerFabric?.fabric?.description} Swatch`}
            height={isMobile ? 300 : 600}
            width={isMobile ? 300 : 600}
          />
        </ImageStepper>
      </Box>
      <Stack
        spacing={4}
        alignItems={{ mobile: 'center', desktop: 'initial' }}
        px={{ laptop: 4 }}
        pt={{ mobile: 50, laptop: 0 }}
        width={{ laptop: 1 / 2 }}
      >
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Typography variant='h3'>
            {visualizerFabric?.fabric
              ? `${visualizerFabric.fabric?.trinityNumber} (${visualizerFabric?.fabric?.position})`
              : `Please Select A ${visualizerFabric ? 'Fabric' : 'Garment'}`}
          </Typography>
          {!isMobile && (
            <Link to='/styleflow/collections'>
              <Button size='small' sx={{ borderRadius: 6 }}>
                Exit To Browser
              </Button>
            </Link>
          )}
        </Stack>
        <Typography>{visualizerFabric?.fabric?.description}</Typography>
        <Divider sx={{ width: 1 }} />
        <Stack
          height={isMobile ? 'auto' : windowSize}
          sx={isMobile ? {} : { scrollbarWidth: 'thin', overflowY: 'scroll', overflowX: 'hidden' }}
        >
          <Outlet />
        </Stack>
      </Stack>
    </Stack>
  )
}
