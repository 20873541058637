import { useState } from 'react'
import { useFetcher, useSearchParams } from 'react-router-dom'
import { Stack, Typography, IconButton } from '@mui/material'
import { KeyboardArrowLeft } from '@mui/icons-material'
import { capitalCase, type MaterialTypes } from '@trinity/utils'
import { CollectionCard, FabricCardList, Pagination, Search } from '../../components'
import { BottomActionBar } from './helpers'

interface StyleflowVisualizeFabricsProps {
  collections: TrinityAPI.CollectionType[]
  totalCollections: number
  materialList: MaterialTypes[]
  material: 'fabric' | 'lining'
}

export function StyleflowVisualizeFabrics({
  collections,
  totalCollections,
  materialList,
  material,
}: StyleflowVisualizeFabricsProps) {
  const [collection, setCollection] = useState<string | number | null>(null)
  const fabricIndex = materialList.findIndex(m => m === material)
  const fetcher = useFetcher<TrinityAPI.FabricExtendedType[]>()
  const [_params, setParams] = useSearchParams()

  return (
    <Stack spacing={4} pb={14}>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent={{ mobile: 'center', laptop: 'flex-start' }}
        spacing={{ laptop: 2 }}
      >
        <IconButton onClick={() => setCollection(null)} sx={{ display: collection ? '' : 'none' }}>
          <KeyboardArrowLeft />
        </IconButton>
        <Typography variant='h5'>{collection ? `${capitalCase(material)}s` : 'Collections'}</Typography>
      </Stack>
      {!collection && (
        <Stack px={1}>
          <Search name='q' method='get' />
        </Stack>
      )}
      {collection ? (
        <FabricCardList
          isBasic
          fabrics={fetcher.data ?? []}
          onClick={fabricId =>
            setParams(params => {
              params.set(`${material}Id`, fabricId)
              return params
            })
          }
        />
      ) : (
        <CollectionList
          collections={collections}
          handleCLick={id => {
            setCollection(id)
            fetcher.submit({ id }, { method: 'POST' })
          }}
        />
      )}
      <Pagination total={totalCollections} pageSizeOptions={[24, 48, 72]} />
      <BottomActionBar lastMaterial={materialList[fabricIndex - 1]} nextMaterial={materialList[fabricIndex + 1]} />
    </Stack>
  )
}

function CollectionList({
  collections,
  handleCLick,
}: {
  collections: TrinityAPI.CollectionType[]
  handleCLick: (id: number | string) => void
}) {
  return (
    <Stack spacing={2} pl={{ laptop: 4 }}>
      {collections.map(collection => (
        <CollectionCard key={collection.id} collection={collection} onClick={() => handleCLick(collection.id)} />
      ))}
    </Stack>
  )
}
