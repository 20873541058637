import { useSearchParams } from 'react-router-dom'

export function useExcludeSearchParams(exclude: string[]) {
  const [params] = useSearchParams()
  const filteredParams = new URLSearchParams()
  params.forEach((value, key) => {
    if (!exclude.includes(key)) {
      filteredParams.append(key, value)
    }
  })

  return filteredParams.toString()
}
