import { Stack, Typography } from '@mui/material'
import { ImageNotFound } from '../../assets'
import { Expand } from '../Animations'

interface MaterialCardProps {
  onClick?: () => void
  image?: string
  headerText: string
  subText?: string
  disabled?: boolean
  selected?: boolean
  error?: boolean
}

export function MaterialCard({
  onClick = () => null,
  image = '',
  headerText,
  subText = '',
  disabled = false,
  selected = false,
  error = false,
}: MaterialCardProps) {
  return (
    <Expand>
      <Stack
        onClick={onClick}
        border={selected ? 'selected' : error ? 'error' : 'none'}
        boxShadow={theme => theme.elevation.button}
        sx={{ opacity: disabled ? '40%' : '100%' }}
      >
        <Stack alignItems='center' justifyContent='center' bgcolor='neutral.extraLight' py={2} px={4}>
          {!disabled && (
            <img
              src={image}
              alt={subText}
              height={190}
              width={190}
              onError={e => (e.currentTarget.src = ImageNotFound)}
            />
          )}
        </Stack>
        <Stack direction='row' alignItems='center' justifyContent='flex-start' p={4}>
          <Stack>
            <Typography noWrap gutterBottom variant='h6' sx={{ maxWidth: 190 }}>
              {headerText}
            </Typography>
            {!disabled && (
              <Typography noWrap variant='smallBody1' sx={{ maxWidth: 190 }}>
                {subText}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Stack>
    </Expand>
  )
}
