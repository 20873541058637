import { Link, Outlet, useNavigate } from 'react-router-dom'
import LazyLoad from 'react-lazyload'
import {
  Breadcrumbs,
  Button,
  Chip,
  Divider,
  Skeleton,
  Stack,
  type SxProps,
  type Theme,
  Typography,
} from '@mui/material'
import { GetApp } from '@mui/icons-material'
import { downloadFabricStatus, formatDate } from '@trinity/utils'
import { useGlobalState } from '../../hooks'
import { FabricCardList } from '../../components'

interface FabricExplorerCollectionProps {
  collection: TrinityAPI.CollectionExtendedType
  children?: React.ReactNode
}

export function FabricExplorerCollection({ collection, children = null }: FabricExplorerCollectionProps) {
  return (
    <Stack spacing={4}>
      <Stack direction='row' justifyContent='space-between'>
        {children}
        <Breadcrumbs
          aria-label='breadcrumb'
          sx={{ display: { mobile: 'none', laptop: 'initial' }, alignSelf: 'flex-end' }}
        >
          <Typography component={Link} variant='smallLink' to='..'>
            Collections
          </Typography>
          <Typography variant='smallBody1'>{collection.name}</Typography>
        </Breadcrumbs>
      </Stack>
      <Stack direction={{ mobile: 'column', laptop: 'row' }} spacing={4} justifyContent='space-between'>
        <Stack
          direction={{ mobile: 'column', laptop: 'row' }}
          spacing={2}
          alignItems={{ mobile: 'center', laptop: 'flex-start' }}
        >
          <img src={collection.image} alt={collection.title} height={100} />
          <div>
            <Typography gutterBottom variant='h4'>
              {collection.title}
            </Typography>
            <Typography variant='body1' sx={{ textAlign: { mobile: 'center', laptop: 'left' } }}>
              {collection.datecode}
            </Typography>
          </div>
        </Stack>
      </Stack>
      <Outlet />
    </Stack>
  )
}

function CollectionButtonWrapper({ children }: { children: React.ReactNode }) {
  return (
    <Stack
      direction={{ mobile: 'column', laptop: 'row' }}
      spacing={1}
      alignItems='center'
      justifyContent={{ mobile: 'center', laptop: 'flex-end' }}
      mt={{ laptop: '-4.6rem !important' }}
    >
      {children}
    </Stack>
  )
}

export function FabricExplorerCollectionDefault({ collection }: { collection: TrinityAPI.CollectionExtendedType }) {
  const navigate = useNavigate()

  return (
    <Stack spacing={4}>
      <CollectionButtonWrapper>
        <Button variant='outlined' onClick={() => navigate('status')}>
          View Fabric Status
        </Button>
        {collection.digitalPdfUrl && (
          <Button
            download
            variant='outlined'
            startIcon={<GetApp fontSize='large' />}
            href={collection.digitalPdfUrl}
            target='_blank'
            sx={{ display: { mobile: 'none', laptop: 'inherit' } }}
          >
            Digital Collection
          </Button>
        )}
      </CollectionButtonWrapper>
      <Divider />
      <FabricCardList fabrics={collection.fabrics} baseUrl='/fabric-explorer/fabrics/:fabricId' />
    </Stack>
  )
}

export function FabricExplorerCollectionStatus({ collection }: FabricExplorerCollectionProps) {
  const { onMobile } = useGlobalState()
  const { user } = useGlobalState()
  const navigate = useNavigate()
  const headers = onMobile ? mobileHeaders : desktopHeaders

  return (
    <Stack spacing={4}>
      <CollectionButtonWrapper>
        <Button onClick={() => navigate('..')}>View Fabric Status</Button>
        <Button
          download
          variant='outlined'
          startIcon={<GetApp fontSize='large' />}
          href={downloadFabricStatus(collection.id, user?.dealer?.id)}
          sx={{ display: { mobile: 'none', laptop: 'inherit' } }}
        >
          Download PDF
        </Button>
      </CollectionButtonWrapper>
      <Stack>
        <Stack direction='row' alignItems='center' sx={{ ...sharedStyles.rowStyles, height: 38, bgcolor: 'grey.100' }}>
          {headers.map(headerTitle => (
            <Typography
              key={headerTitle}
              variant='htable'
              sx={{ flex: sharedStyles.columnFlexValues[headerTitle as HeaderTitles], textTransform: 'uppercase' }}
            >
              {headerTitle}
            </Typography>
          ))}
        </Stack>
        {collection.fabrics.map(fabric => (
          <LazyLoad key={fabric.id} placeholder={<SkeletonStatusRow fabric={fabric} />}>
            <Stack direction='row' sx={{ ...sharedStyles.rowStyles, py: 2, borderBottom: 'n100' }}>
              <Fabric fabric={fabric} />
              {!onMobile && <Description fabric={fabric} />}
              <Factory fabric={fabric} />
              {!onMobile && <Readiness fabric={fabric} />}
              <Status fabric={fabric} />
              {!onMobile && <Restock fabric={fabric} />}
              {!onMobile && <LastUpdate fabric={fabric} />}
            </Stack>
          </LazyLoad>
        ))}
      </Stack>
    </Stack>
  )
}

const sharedStyles: SharedStyleType = {
  rowStyles: { px: { mobile: 2, laptop: 4 } },
  cellSpacing: 2.5,
  columnFlexValues: {
    fabric: 1,
    description: 2,
    factory: 1,
    readiness: 1,
    status: 1,
    restock: 1,
    'last update': 1,
  },
}
const mobileHeaders = ['fabric', 'factory', 'status']
const desktopHeaders = Object.keys(sharedStyles.columnFlexValues)

//* TYPES
interface ColumnFlexValuesType {
  fabric: number
  description: number
  factory: number
  readiness: number
  status: number
  restock: number
  'last update': number
}

interface SharedStyleType {
  rowStyles: SxProps<Theme>
  cellSpacing: React.ComponentProps<typeof Stack>['spacing']
  columnFlexValues: ColumnFlexValuesType
}

type HeaderTitles = keyof ColumnFlexValuesType

interface FabricProps {
  fabric: TrinityAPI.FabricExtendedType
}

//* HELPER COMPONENTS
function SkeletonStatusRow({ fabric }: FabricProps) {
  const { onMobile } = useGlobalState()
  const factoryMap = Object.keys(fabric.factories)

  const SkeletonHelper = ({ width }: { width: number }) => (
    <Stack spacing={2} sx={{ flex: 1 }}>
      {factoryMap.map(name => (
        <Typography key={name} variant='smallBody1'>
          <Skeleton sx={{ width }} />
        </Typography>
      ))}
    </Stack>
  )
  return (
    <Stack direction='row' sx={{ ...sharedStyles.rowStyles, py: 2, borderBottom: 'n100' }}>
      {/* //* FABRIC */}
      <Typography variant='smallBody1' sx={{ flex: 1 }}>
        <Skeleton sx={{ width: 3 / 5 }} />
      </Typography>
      {/* //* DESCRIPTION */}
      {!onMobile && (
        <Typography variant='smallBody1' sx={{ flex: 2 }}>
          <Skeleton sx={{ width: 4 / 5 }} />
        </Typography>
      )}
      {/* //* FACTORY */}
      <SkeletonHelper width={1 / 3} />
      {/* //* READINESS */}
      {!onMobile && <SkeletonHelper width={2 / 3} />}
      {/* //* STATUS */}
      <SkeletonHelper width={1 / 3} />
      {/* //* RESTOCK */}
      {!onMobile && <SkeletonHelper width={2 / 3} />}
      {/* //* LAST UPDATE */}
      {!onMobile && <SkeletonHelper width={2 / 3} />}
    </Stack>
  )
}

const alignValue = (factories: TrinityAPI.FactoryType) => (Object.keys(factories).length > 1 ? 'flex-start' : 'center')

const Fabric = ({ fabric }: FabricProps) => {
  const { onMobile } = useGlobalState()

  return (
    <Stack
      justifyContent={alignValue(fabric.factories)}
      spacing={sharedStyles.cellSpacing}
      sx={{ flex: sharedStyles.columnFlexValues.fabric }}
    >
      <Typography component={Link} to={`/fabric-explorer/fabrics/${fabric.id}`} variant='smallLink'>
        {fabric.trinityFabricNumber}
      </Typography>
      {onMobile && (
        <Typography variant='smallBody1' sx={{ maxHeight: 35, overflow: 'hidden' }}>
          {fabric.description}
        </Typography>
      )}
    </Stack>
  )
}

const Description = ({ fabric }: FabricProps) => (
  <Typography
    variant='smallBody1'
    sx={{ flex: sharedStyles.columnFlexValues.description, alignSelf: alignValue(fabric.factories) }}
  >
    {fabric.description}
  </Typography>
)

const Factory = ({ fabric }: FabricProps) => (
  <Stack
    justifyContent='center'
    spacing={sharedStyles.cellSpacing}
    sx={{ flex: sharedStyles.columnFlexValues.factory, pl: 1 }}
  >
    {Object.keys(fabric.factories).map(factoryName => (
      <Typography key={factoryName} variant='smallBody3' sx={{ fontWeight: 700, textTransform: 'capitalize' }}>
        {factoryName}
      </Typography>
    ))}
  </Stack>
)

const Readiness = ({ fabric }: FabricProps) => (
  <Stack
    justifyContent='center'
    spacing={sharedStyles.cellSpacing}
    sx={{ flex: sharedStyles.columnFlexValues.readiness }}
  >
    {Object.keys(fabric.factories).map(factoryName => (
      <Typography key={factoryName} variant='smallBody1' sx={{ textTransform: 'capitalize' }}>
        {fabric.factories[factoryName as keyof TrinityAPI.FactoryType]?.readiness}
      </Typography>
    ))}
  </Stack>
)

const Status = ({ fabric }: FabricProps) => (
  <Stack
    justifyContent='center'
    spacing={(sharedStyles.cellSpacing as number) - 0.5}
    sx={{ flex: sharedStyles.columnFlexValues.status }}
  >
    {Object.keys(fabric.factories).length < 1 && (
      <Chip
        size='small'
        label={<Typography variant='label'>Not Available</Typography>}
        color='error'
        sx={{ width: 'fit-content' }}
      />
    )}
    {Object.keys(fabric.factories).map(factoryName => {
      const factory = fabric.factories[factoryName as keyof TrinityAPI.FactoryType]
      return (
        <Chip
          key={factoryName}
          size='small'
          label={<Typography variant='label'>{factory?.inStock ? 'In Stock' : 'Temp Out'}</Typography>}
          color={factory?.inStock ? 'success' : 'warning'}
          sx={{ width: 'fit-content' }}
        />
      )
    })}
  </Stack>
)

const Restock = ({ fabric }: FabricProps) => (
  <Stack
    justifyContent='center'
    spacing={sharedStyles.cellSpacing}
    sx={{ flex: sharedStyles.columnFlexValues.restock }}
  >
    {Object.keys(fabric.factories).map(factoryName => {
      const factory = fabric.factories[factoryName as keyof TrinityAPI.FactoryType]
      return (
        <Typography key={factoryName} variant='smallBody1'>
          {factory?.restockDate ? formatDate(factory.restockDate) : '--'}
        </Typography>
      )
    })}
  </Stack>
)

const LastUpdate = ({ fabric }: FabricProps) => (
  <Stack
    justifyContent='center'
    spacing={sharedStyles.cellSpacing}
    sx={{ flex: sharedStyles.columnFlexValues['last update'] }}
  >
    {Object.keys(fabric.factories).map(factoryName => {
      const factory = fabric.factories[factoryName as keyof TrinityAPI.FactoryType]
      return (
        <Typography key={factoryName} variant='smallBody1'>
          {factory?.lastEditDate ? formatDate(new Date(factory.lastEditDate)) : '--'}
        </Typography>
      )
    })}
  </Stack>
)
