import { Link } from 'react-router-dom'
import { Card, CardMedia, Stack, Typography } from '@mui/material'
import { Expand } from '../Animations'

interface CollectionCardProps {
  collection: TrinityAPI.CollectionType
  onClick?: () => void
}

export function CollectionCard({ collection, onClick = undefined }: CollectionCardProps) {
  return (
    <Expand>
      {onClick ? (
        <BaseCard collection={collection} onClick={onClick} />
      ) : (
        <Link to={String(collection.id)} style={{ textDecoration: 'none' }}>
          <BaseCard collection={collection} onClick={onClick} />
        </Link>
      )}
    </Expand>
  )
}

function BaseCard({ collection, onClick = undefined }: CollectionCardProps) {
  return (
    <Card
      onClick={onClick}
      sx={{
        display: 'flex',
        flexDirection: 'row',
        height: 141,
        width: { mobile: 320, tablet: 380 },
        pl: 2,
        pt: 3,
        boxShadow: theme => theme.elevation.card2,
      }}
    >
      <CardMedia image={collection.image} sx={{ height: 90, width: 90, mr: 2 }} />
      <Stack>
        <Typography gutterBottom variant='h6' sx={{ maxWidth: { mobile: 190, laptop: 250 } }}>
          {collection.title}
        </Typography>
        <Typography variant='subtitle1'>{collection.datecode}</Typography>
      </Stack>
    </Card>
  )
}
