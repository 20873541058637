import { useSearchParams } from 'react-router-dom'
import { Stack, Typography, Unstable_Grid2 as Grid } from '@mui/material'
import { type MaterialTypes } from '@trinity/utils'
import { MaterialCard, Search } from '../../components'
import { useGlobalState } from '../../hooks'
import { BottomActionBar } from './helpers'

interface StyleflowVisualizeRibknitsProps {
  ribknits: TrinityAPI.RibknitType[]
  materialList: MaterialTypes[]
}

export function StyleflowVisualizeRibknits({ ribknits, materialList }: StyleflowVisualizeRibknitsProps) {
  const { onMobile, onTablet } = useGlobalState()
  const isMobile = onMobile || onTablet
  const ribknitIndex = materialList.findIndex(material => material === 'ribknit')

  return (
    <Stack spacing={4} pb={14}>
      <Typography variant='h5' align={isMobile ? 'center' : 'left'}>
        Ribknits
      </Typography>
      <Stack px={1}>
        <Search name='q' method='get' />
      </Stack>
      <RibknitList ribknits={ribknits} />
      <BottomActionBar lastMaterial={materialList[ribknitIndex - 1]} nextMaterial={materialList[ribknitIndex + 1]} />
    </Stack>
  )
}

function RibknitList({ ribknits }: Pick<StyleflowVisualizeRibknitsProps, 'ribknits'>) {
  const [params, setParams] = useSearchParams()

  return (
    <Grid container spacing={2} justifyContent={{ mobile: 'center', laptop: 'flex-start' }}>
      {ribknits.map(ribknit => (
        <Grid key={ribknit.id}>
          <MaterialCard
            onClick={() =>
              setParams(params => {
                params.set('ribknitId', String(ribknit.id))
                return params
              })
            }
            image={ribknit.image}
            headerText={ribknit.description}
            selected={String(ribknit.id) === params.get('ribknitId')}
          />
        </Grid>
      ))}
    </Grid>
  )
}
