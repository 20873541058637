import { useState } from 'react'
import { IconButton, MobileStepper, Stack, type StackProps } from '@mui/material'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'

export function ImageStepper({ children, ...props }: StackProps) {
  const [activeStep, setActiveStep] = useState(0)
  const images = getImages(children)
  const maxSteps = images.sources.length

  return (
    <Stack {...props}>
      <img
        src={images.sources[activeStep]}
        alt={images.descriptions[activeStep]}
        width={images.widths[activeStep]}
        height={images.heights[activeStep]}
        style={{ zIndex: 1 }}
      />
      <MobileStepper
        variant='dots'
        steps={maxSteps}
        position='static'
        activeStep={activeStep}
        sx={{
          bgcolor: 'inherit',
          '&>.MuiMobileStepper-dots': {
            '&>.MuiMobileStepper-dot': { bgcolor: 'grey.300', height: 12, width: 12 },
            '&>.MuiMobileStepper-dotActive': { bgcolor: 'common.black' },
          },
        }}
        nextButton={
          <IconButton
            onClick={() => setActiveStep(prevActiveStep => prevActiveStep + 1)}
            disabled={activeStep === maxSteps - 1}
          >
            <KeyboardArrowRight fontSize='large' />
          </IconButton>
        }
        backButton={
          <IconButton onClick={() => setActiveStep(prevActiveStep => prevActiveStep - 1)} disabled={activeStep === 0}>
            <KeyboardArrowLeft fontSize='large' />
          </IconButton>
        }
      />
    </Stack>
  )
}

const getImages = (children: React.ReactNode) => {
  const images: ImagesType = { sources: [], descriptions: [], heights: [], widths: [] }

  if (Array.isArray(children)) {
    children.forEach(child => {
      images.sources.push(child.props.src)
      images.descriptions.push(child.props.alt)
      images.heights.push(child.props.height)
      images.widths.push(child.props.width)
    })
  }

  return images
}

type ImagesType = {
  sources: string[]
  descriptions: string[]
  heights: number[]
  widths: number[]
}
