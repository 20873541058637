import { useSearchParams } from 'react-router-dom'
import { Stack, Typography, Unstable_Grid2 as Grid } from '@mui/material'
import { type MaterialTypes } from '@trinity/utils'
import { MaterialCard, Pagination, Search } from '../../components'
import { useGlobalState } from '../../hooks'
import { BottomActionBar } from './helpers'

interface StyleflowVisualizeThreadsProps {
  threads: TrinityAPI.ThreadType[]
  totalThreads: number
  materialList: MaterialTypes[]
}

export function StyleflowVisualizeThreads({ threads, totalThreads, materialList }: StyleflowVisualizeThreadsProps) {
  const { onMobile, onTablet } = useGlobalState()
  const isMobile = onMobile || onTablet
  const threadIndex = materialList.findIndex(material => material === 'thread')

  return (
    <Stack spacing={4} pb={14}>
      <Typography variant='h5' align={isMobile ? 'center' : 'left'}>
        Threads
      </Typography>
      <Stack px={1}>
        <Search name='q' method='get' />
      </Stack>
      <ThreadList threads={threads} />
      <Pagination total={totalThreads} pageSizeOptions={[24, 48, 72]} />
      <BottomActionBar lastMaterial={materialList[threadIndex - 1]} nextMaterial={materialList[threadIndex + 1]} />
    </Stack>
  )
}

function ThreadList({ threads }: Pick<StyleflowVisualizeThreadsProps, 'threads'>) {
  const [params, setParams] = useSearchParams()

  return (
    <Grid container spacing={2} justifyContent={{ mobile: 'center', laptop: 'flex-start' }}>
      {threads.map(thread => (
        <Grid key={thread.id}>
          <MaterialCard
            onClick={() =>
              setParams(params => {
                params.set('threadId', String(thread.id))
                return params
              })
            }
            image={thread.image}
            headerText={thread.code ?? ''}
            subText={thread.description}
            selected={String(thread.id) === params.get('threadId')}
          />
        </Grid>
      ))}
    </Grid>
  )
}
