import { useLocation, useNavigate, Link } from 'react-router-dom'
import { Stack, Typography, Unstable_Grid2 as Grid, Button } from '@mui/material'
import { capitalCase } from '@trinity/utils'
import { ImageNotFound } from '../../assets'
import { Expand, MaterialCard } from '../../components'

interface StyleflowVisualizeMaterialsProps {
  visualizerFabric?: TrinityAPI.VisualizerFabricType
}

export function StyleflowVisualizeMaterials({ visualizerFabric = undefined }: StyleflowVisualizeMaterialsProps) {
  return (
    <Stack spacing={4} pb={8}>
      <GarmentCard visualizerFabric={visualizerFabric} />
      {visualizerFabric && <MaterialCards visualizerFabric={visualizerFabric} />}
      {visualizerFabric && (
        <Stack
          direction={{ mobile: 'column', desktop: 'row' }}
          alignItems='center'
          justifyContent='center'
          spacing={{ mobile: 2, laptop: 4 }}
        >
          <Button onClick={() => alert('Not Yet Implemented')} sx={{ borderRadius: 50, width: 'fit-content' }}>
            Add To Cart
          </Button>
          <Button
            variant='outlined'
            onClick={() => alert('Not Yet Implemented')}
            sx={{ borderRadius: 50, width: 'fit-content' }}
          >
            Share Garment
          </Button>
        </Stack>
      )}
    </Stack>
  )
}

function GarmentCard({ visualizerFabric = undefined }: StyleflowVisualizeMaterialsProps) {
  const location = useLocation()

  return (
    <Expand>
      <Stack
        component={Link}
        to={`garmentType${location.search}`}
        direction='row'
        spacing={4}
        alignItems='center'
        p={2}
        mx={{ laptop: 2 }}
        sx={{ boxShadow: theme => theme.elevation.button, textDecoration: 'none' }}
      >
        <img
          src={visualizerFabric?.garmentType.image ?? ImageNotFound}
          alt={visualizerFabric?.garmentType.formalName}
          height={85}
          width={70}
        />
        <Stack>
          <Typography variant='h6'>Garment Type</Typography>
          <Typography variant='smallBody1'>
            {visualizerFabric
              ? `${visualizerFabric?.garmentType.formalName} | ${visualizerFabric?.description}`
              : 'Please Choose'}
          </Typography>
        </Stack>
      </Stack>
    </Expand>
  )
}

function MaterialCards({ visualizerFabric }: Required<StyleflowVisualizeMaterialsProps>) {
  const navigate = useNavigate()
  const { relevantMaterials } = visualizerFabric

  return (
    <Grid container spacing={2} justifyContent={{ mobile: 'center', laptop: 'flex-start' }}>
      {relevantMaterials.map(material => {
        const materialType = visualizerFabric[material]

        return (
          <Grid key={material}>
            <MaterialCard
              error={!materialType}
              onClick={() => navigate(`${material}${location.search}`)}
              image={materialType?.image}
              headerText={materialType ? capitalCase(material) : `Please Select ${capitalCase(material)}`}
              subText={isFabric(materialType) ? materialType.trinityNumber : materialType?.description}
            />
          </Grid>
        )
      })}
    </Grid>
  )
}

//* HELPERS
const isFabric = (material: Record<string, unknown> | null): material is { trinityNumber: string } => {
  if (!material) return false

  return 'trinityNumber' in material
}
