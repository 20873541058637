import { useState } from 'react'
import { useFetcher, useSearchParams } from 'react-router-dom'
import { Stack, Typography, Unstable_Grid2 as Grid, IconButton } from '@mui/material'
import { KeyboardArrowLeft } from '@mui/icons-material'
import { ALL_GARMENTS, BREEK, COAT_PANT_PANT, COAT_VEST_PANT_PANT, SHIRT, SWACKET, TOPCOAT } from '@trinity/utils'
import { MaterialCard } from '../../components'
import { BottomActionBar } from './helpers'

export function StyleflowVisualizeGarmentType() {
  const [garmentType, setGarmentType] = useState<number | null>(null)
  const fetcher = useFetcher<ModelCardsProps>()

  const handleGarmentType = (garmentType: number) => {
    setGarmentType(garmentType)
    fetcher.submit({ garmentType }, { method: 'POST' })
  }

  return (
    <Stack spacing={4} pb={14}>
      <Stack
        direction='row'
        alignItems='center'
        justifyContent={{ mobile: 'center', laptop: 'flex-start' }}
        spacing={{ laptop: 2 }}
      >
        <IconButton onClick={() => setGarmentType(null)} sx={{ display: garmentType ? '' : 'none' }}>
          <KeyboardArrowLeft />
        </IconButton>
        <Typography variant='h5'>{garmentType ? 'Models' : 'Garment'}</Typography>
      </Stack>
      <Grid container spacing={2} justifyContent={{ mobile: 'center', laptop: 'flex-start' }}>
        {garmentType ? <ModelCards models={fetcher?.data?.models} /> : <GarmentCards handleClick={handleGarmentType} />}
      </Grid>
      <BottomActionBar nextMaterial='fabric' />
    </Stack>
  )
}

function GarmentCards({ handleClick }: GarmentCardsProps) {
  return FILTERED_GARMENTS.map(garment => (
    <Grid key={garment.bitmask}>
      <MaterialCard
        image={garment.image ?? ''}
        headerText={garment.alternateName ?? garment.formalName}
        onClick={() => handleClick(garment.bitmask)}
      />
    </Grid>
  ))
}

function ModelCards({ models }: ModelCardsProps) {
  const [params, setParams] = useSearchParams()

  const handleClick = (model: ModelType) => {
    const currentGarmentType = Number(params.get('garmentType'))
    const hasShirt = currentGarmentType === SHIRT.bitmask || model.garmentType === SHIRT.bitmask
    const bothShirt = currentGarmentType === SHIRT.bitmask && model.garmentType === SHIRT.bitmask
    if (hasShirt && !bothShirt) {
      setParams({ garmentType: String(model.garmentType), baseModelId: String(model.id) })
    } else {
      const hasTopcoat = currentGarmentType === TOPCOAT.bitmask || model.garmentType === TOPCOAT.bitmask
      const bothTopcoat = currentGarmentType === TOPCOAT.bitmask && model.garmentType === TOPCOAT.bitmask
      setParams(params => {
        if (hasTopcoat && !bothTopcoat) {
          params.delete('buttonId')
        }
        params.set('baseModelId', String(model.id))
        params.set('garmentType', String(model.garmentType))
        return params
      })
    }
  }

  return models?.map(model => (
    <Grid key={model.id}>
      <MaterialCard
        selected={String(model.id) === params.get('baseModelId')}
        image={model.image}
        headerText={model.description}
        onClick={() => handleClick(model)}
      />
    </Grid>
  ))
}

type ModelType = { id: number; description: string; image: string; garmentType: number }

interface ModelCardsProps {
  models?: ModelType[]
}

interface GarmentCardsProps {
  handleClick: (id: number) => void
}

const INVALID_GARMENT_TYPES: TrinityAPI.GarmentTypesType[] = [COAT_PANT_PANT, COAT_VEST_PANT_PANT, BREEK, SWACKET]
const FILTERED_GARMENTS = ALL_GARMENTS.filter(garment => !INVALID_GARMENT_TYPES.includes(garment))
