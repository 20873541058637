import { Link } from 'react-router-dom'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Divider,
  Stack,
  Typography,
  Box,
  Card,
  CardContent,
  CardMedia,
  Button,
  Chip,
} from '@mui/material'
import { ArrowForward, Launch, Warning } from '@mui/icons-material'
import { ALL_GARMENTS, continueOrderUrl, editOrderUrl, formatDate, viewOrderUrl } from '@trinity/utils'
import { ImageNotFound } from '../../assets'
import { StatusChip } from '../../components'
import { useOrderStatus } from './OrderStatusProvider'

interface OrderProps {
  order: TrinityAPI.OrderHomeType
  children: React.ReactNode
}

export function OrderStatusAccordion({ order, children }: OrderProps) {
  return (
    <Accordion>
      <AccordionSummary>{children}</AccordionSummary>
      <AccordionDetails sx={{ p: 0, bgcolor: 'grey.50' }}>
        <AccordionDetailsHeader order={order} />
        {order.garments.map(garment => (
          <AccordionDetailsBody key={garment.id} garment={garment} />
        ))}
        <AccordionDetailsFooter order={order} />
      </AccordionDetails>
    </Accordion>
  )
}

function AccordionDetailsHeader({ order }: Pick<OrderProps, 'order'>) {
  const { onMobile, INCOMPLETE, currentScope } = useOrderStatus()

  if (onMobile) {
    return (
      <Stack spacing={3} py={3} alignItems='center' justifyContent='center'>
        <Typography variant='h5'>{order.title}</Typography>
        <Divider sx={{ width: 1 }} />
        {order.customOrderNumber && (
          <>
            <Typography sx={{ px: 4 }}>{order.customOrderNumber}</Typography>
            <Divider sx={{ width: 1 }} />
          </>
        )}
      </Stack>
    )
  }

  return (
    <>
      {order.customOrderNumber && (
        <Typography align='center' color='grey.900' sx={{ py: 2 }}>
          <strong>Order Description:</strong> {order.customOrderNumber}
        </Typography>
      )}
      <Stack direction='row' sx={{ backgroundColor: 'grey.100', px: 4, py: 1 }}>
        <Typography variant='htable' sx={{ flex: 0.75 }}>
          GARMENT ID#
        </Typography>
        <Typography variant='htable' sx={{ flex: 1.5 }}>
          FABRIC
        </Typography>
        {currentScope !== INCOMPLETE && (
          <Typography variant='htable' sx={{ flex: 1.5 }}>
            STATUS
          </Typography>
        )}
      </Stack>
    </>
  )
}

function AccordionDetailsBody({ garment }: { garment: TrinityAPI.OrderHomeGarmentType }) {
  const { onMobile, INCOMPLETE, currentScope } = useOrderStatus()
  const garmentName = ALL_GARMENTS.find(g => g.abbreviation === garment.garmentType)?.formalName

  return (
    <Stack
      direction={onMobile ? 'column' : 'row'}
      alignItems='center'
      spacing={{ mobile: 2, laptop: 0 }}
      sx={{ px: 4, py: { mobile: 4, laptop: 0 }, borderBottom: 'n100' }}
    >
      <Box sx={{ width: { mobile: 1, laptop: 1 / 5 } }}>
        <Typography variant='h6'>{garment.title}</Typography>
        <Typography variant='smallBody1'>{garmentName}</Typography>
      </Box>
      <FabricDetails fabric={garment.fabric} />
      {currentScope !== INCOMPLETE && (
        <Stack spacing={1} alignItems={{ laptop: 'center' }} pt={0} width={{ mobile: 1, laptop: 1 / 5 }}>
          <Box>
            <StatusChip status={garment.orderStatus.description} />
          </Box>
          <Typography variant='smallBody1' sx={{ pb: 0.75, pl: { mobile: 1, laptop: 0 } }}>
            {formatDate(new Date(garment.statusUpdatedAt))}
          </Typography>
        </Stack>
      )}
      <Box sx={{ width: { mobile: 1, laptop: 1 / 5 }, textAlign: { mobile: 'left', laptop: 'right' } }}>
        <AccordionDetailsBodyAction garment={garment} />
      </Box>
    </Stack>
  )
}

function AccordionDetailsFooter({ order }: Pick<OrderProps, 'order'>) {
  const { DELAYED, INCOMPLETE, currentScope } = useOrderStatus()

  return (
    <Stack
      direction='row'
      alignItems='center'
      justifyContent={{ mobile: 'flex-start', laptop: 'space-between' }}
      sx={{ p: 3 }}
    >
      <Typography variant='h6' sx={{ display: { mobile: 'none', laptop: 'initial' } }}>
        {order.title}
      </Typography>
      {currentScope === DELAYED && (
        <Chip
          icon={<Warning />}
          label={`${order.garments.length} of ${order.garmentCount} items have a delay.`}
          sx={{
            display: { mobile: 'none', laptop: 'flex' },
            backgroundColor: 'error.extraLight',
            borderRadius: 0,
            p: 3,
            '& .MuiChip-icon': { color: 'error.main' },
            '& .MuiChip-label': { color: 'error.main' },
          }}
        />
      )}
      {currentScope === INCOMPLETE ? (
        <Button
          variant='outlined'
          href={continueOrderUrl(order.id)}
          target='_blank'
          rel='noreferrer'
          endIcon={<ArrowForward />}
        >
          Continue Order
        </Button>
      ) : (
        <Button variant='text' href={viewOrderUrl(order.id)} target='_blank' rel='noreferrer' endIcon={<Launch />}>
          View Order Details
        </Button>
      )}
    </Stack>
  )
}

//* Abstractions to help clean up AccordionDetailsBody a bit
function FabricDetails({ fabric }: { fabric: TrinityAPI.FabricType }) {
  const { trinityFabricNumber, id, description, url } = fabric

  return (
    <Card sx={{ width: { mobile: 1, laptop: 2 / 5 }, bgcolor: 'grey.50', boxShadow: 0 }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: { mobile: 'column', laptop: 'row' },
          alignItems: { mobile: 'flex-start', laptop: 'center' },
        }}
      >
        <CardMedia
          component='img'
          image={url}
          alt={trinityFabricNumber}
          sx={{
            border: 'n100',
            height: { mobile: 140, laptop: 65 },
            width: { mobile: 288, laptop: 132 },
          }}
          onError={e => (e.currentTarget.src = ImageNotFound)}
        />
        <CardContent sx={{ pl: { mobile: 0, laptop: 2 } }}>
          <Typography
            gutterBottom
            variant='link'
            component={Link}
            to={`/fabric-explorer/fabrics/${id}`}
            target='_blank'
          >
            {trinityFabricNumber}
          </Typography>
          <Typography
            noWrap
            variant='smallBody1'
            component='div'
            sx={{ maxWidth: { laptop: 100, desktop: 180 }, mt: 1 }}
          >
            {description}
          </Typography>
        </CardContent>
      </Box>
    </Card>
  )
}

function AccordionDetailsBodyAction({ garment }: { garment: TrinityAPI.OrderHomeGarmentType }) {
  const { getOrderStatus, DELAYED, EDITABLE, DELIVERY, onSkiffle, currentScope } = useOrderStatus()
  const orderStatus = getOrderStatus(currentScope, garment)

  if (currentScope === DELAYED || orderStatus === DELAYED) {
    return (
      <Typography variant='smallBody1' color='error'>
        {garment.delayStatus.description}
      </Typography>
    )
  }

  if (orderStatus === EDITABLE && !onSkiffle) {
    return (
      <Button variant='outlined' href={editOrderUrl(garment.orderId, garment.id)} target='_blank' rel='noreferrer'>
        Edit
      </Button>
    )
  }

  if (orderStatus === DELIVERY && garment?.shipment?.link) {
    return (
      <Stack>
        <Typography
          gutterBottom
          variant='link'
          component='a'
          href={garment.shipment.link}
          target='_blank'
          rel='noreferrer'
        >
          {garment.shipment.trackingNumber}
        </Typography>
        <Typography variant='smallBody1'>via {garment.shipment.carrier}</Typography>
      </Stack>
    )
  }

  return null
}
