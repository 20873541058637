import { Link } from 'react-router-dom'
import { Button, type ButtonProps } from '@mui/material'
import { ArrowBack } from '@mui/icons-material'

interface BackButtonProps extends ButtonProps {
  to: string
}

export function BackButton({ to, sx, ...props }: BackButtonProps) {
  return (
    <Button
      component={Link}
      to={to}
      variant='text'
      startIcon={<ArrowBack sx={{ color: 'neutral.main' }} />}
      sx={{ width: 'fit-content', typography: 'smallBody1', ...sx }}
      {...props}
    />
  )
}
